import { Box, Input, Tooltip } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import { Info } from "@material-ui/icons";
import MuiAlert from "@material-ui/lab/Alert";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { createMessage } from "../../api/api";
import { CLOUDFRONT_UPLOADS } from "../../constants/hosts";
import { calculateSessionRateWithAdditionalAthletes, checkMessageForSensitiveInfo } from "../../utils/shared";
import { AuthContext } from "../../screens/App/App";
import LoginSignupDialog from "../LoginSignupDialog/LoginSignupDialog";
import "./AthleteMessageCoach.scss";
import blank_avatar from "./avatar.png";

const BLOCKED_FROM_MESSAGING_MESSAGE = "Your account is temporarily restricted due to activities that don't align with our community guidelines. This means you can’t send messages right now. Please contact our support team to regain access: community@athletesuntapped.com"

function Alert(props) {
  return <MuiAlert elevation={10} variant="filled" {...props} />;
}

const AthleteMessageCoach = (props) => {
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [messageContent, setMessageContent] = useState("");
  const [openSnackbar, setOpenSnackBar] = useState(false);
  const [openErrorSnackbar, setOpenErrorSnackBar] = useState(false);
  const [error, setError] = useState("");
  const [numSessions, setNumSessions] = useState(5);
  const [numAthletes, setNumAthletes] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [showSignup, setShowSignup] = useState(false);
  const [
    sessionRateWithAdditionalAthletes,
    setSessionRateWithAdditionalAthletes,
  ] = useState(props.coach.sessionRate);
  const [messagingBlocked, setMessagingBlocked] = useState(false);
  const [warning, setWarning] = useState(null);
  const [showWarningAlert, setShowWarningAlert] = useState(false);

  const sendMessage = useCallback((message) => {
    if (messagingBlocked) {
      setError(BLOCKED_FROM_MESSAGING_MESSAGE);
      setOpenErrorSnackBar(true);
      return;
    }

    const messageToSend = {
      to: props.coach.firebaseID,
      from: user.firebaseUser.uid,
      content: message,
      time: Date.now(),
    };

    user.firebaseUser.getIdToken().then(function (idToken) {
      createMessage(idToken, messageToSend)
        .then((response) => {
          setOpenSnackBar(true);
          setMessageContent("");
        })
        .catch((e) => {
          console.error("Message error:", e);
          if (e === "Messaging not allowed for this user") {
            setError(BLOCKED_FROM_MESSAGING_MESSAGE);
            setMessagingBlocked(true);
          } else {
            setError("Something went wrong. Please try again later.");
          }
          setOpenErrorSnackBar(true);
        });
    });
  }, [props?.coach?.firebaseID, user, messagingBlocked]);
  const book = () => {
    history.push({
      pathname: "/book-session/" + props.coach.id,
      state: { numSessions, numAthletes, sessionRateWithAdditionalAthletes },
    });
  };

  const login = () => {
    setShowModal(true);
    setShowSignup(false);
  };

  const signup = () => {
    setShowModal(true);
    setShowSignup(true);
  };

  useEffect(() => {
    const effectiveRate = calculateSessionRateWithAdditionalAthletes({
      sessionRateString: props.coach.sessionRate,
      additionalAthletesRateString: props.coach.additionalAthletesRate,
      numAthletes,
    });

    setSessionRateWithAdditionalAthletes(effectiveRate);
  }, [props.coach, numAthletes, numSessions]);

  useEffect(() => {
    if (user) {
      const savedMessageContent = localStorage.getItem("messageContent");
      if (savedMessageContent) {
        setMessageContent(savedMessageContent);
        try {
          checkMessageForSensitiveInfo(savedMessageContent)
          sendMessage(savedMessageContent);
        } catch (e) {
          setError("Hey there! All communication & bookings are required to stay on our platform. If you need assistance, contact our support team at support@athletesuntapped.com.");
          setOpenErrorSnackBar(true);
        }
        localStorage.removeItem("messageContent");
        history.push("/dashboard");
      }
    }
  }, [user, sendMessage, history]);

  useEffect(() => {
    if (user?.profile?.messaging_blocked) {
      setMessagingBlocked(user.profile.messaging_blocked);
    }
  }, [user?.profile?.messaging_blocked]);

  return (
    <div className="messageCoachContainer">
      <div className="left">
        <h2>Contact {props.coach.firstName}</h2>
        <div className="coachInfo">
          {props.coach && props.coach["profileImage"] ? (
            <div
              className="coachProfileHeaderAvatar"
              style={{
                backgroundImage:
                  "url('" +
                  CLOUDFRONT_UPLOADS +
                  props.coach["profileImage"] +
                  "')",
              }}
            ></div>
          ) : (
            <div
              className="coachProfileHeaderAvatar"
              style={{ backgroundImage: "url('" + blank_avatar + "')" }}
            ></div>
          )}
          <div>
            <span className="text-bold">
              {props.coach.firstName} {props.coach.lastName.substr(0, 1)}
            </span>
            <br />
            {props.coach.locationInfo.city}, {props.coach.locationInfo.state}
            <br />
            <span className="uc-words">
              Private {props.coach.sport.join(", ")} Coach
            </span>
          </div>
        </div>
        <div className="questions">
          <h4>Most parents/athletes ask about</h4>

          <em>Availability</em>
          <div className="bulletedQuestion">
            <div className="bullet" />
            <div className="text">
              Ex: Are you available to train on the weekends? Or how about
              weeknights after 5pm?
            </div>
          </div>
          <em>Location</em>
          <div className="bulletedQuestion">
            <div className="bullet" />
            <div className="text">
              Ex: Do you have a place where you typically train? Or can we find
              somewhere closer to us?
            </div>
          </div>
          <em>Goals</em>
          <div className="bulletedQuestion">
            <div className="bullet" />
            <div className="text">
              Ex: My son needs to improve his quickness and footwork in order to
              make the team next year. Is this something you can help with?
            </div>
          </div>
        </div>
        <div className="message">
          <h4>Message {props.coach.firstName}</h4>
          {user == null ? (
            // encryted messaging functionality [ prevents email and phone nos. sending ]
            <div className="login-message">
              <textarea
                value={messageContent}
                onChange={(e) => setMessageContent(e.target.value)}
              ></textarea>
              <Button
                className="buttonPrimary"
                onClick={(e) => {
                  setShowModal(true);
                  setShowSignup(true);
                  localStorage.setItem("messageContent", messageContent);
                }}
              >
                Send Message
              </Button>
            </div>
          ) : (
            <div>
              <textarea
                placeholder={messagingBlocked ? BLOCKED_FROM_MESSAGING_MESSAGE : "Type a message..."}
                disabled={messagingBlocked}
                value={messageContent}
                style={messagingBlocked ? { 
                  backgroundColor: '#f0f0f0', 
                  fontSize: '12px', 
                  fontWeight: 'normal',
                  height: '125px' 
                } : {}}
                onChange={(e) => setMessageContent(e.target.value)}
              ></textarea>
              <Button
                className="buttonPrimary"
                disabled={messagingBlocked}
                onClick={(e) => {
                  try {
                    checkMessageForSensitiveInfo(messageContent);
                    sendMessage(messageContent);
                  } catch (e) {
                    setWarning("Hey there! All communication & bookings are required to stay on our platform. Your message was flagged for potentially sensitive information. If you need assistance, contact our support team at support@athletesuntapped.com.");
                    setShowWarningAlert(true);
                  }
                }}
              >
                Send Message
              </Button>
            </div>
          )}
          <Snackbar open={openSnackbar} onClose={(e) => setOpenSnackBar(false)}>
            <Alert onClose={(e) => setOpenSnackBar(false)} severity="success">
              Message Sent!
            </Alert>
          </Snackbar>
          <Snackbar open={openErrorSnackbar} onClose={(e) => setOpenErrorSnackBar(false)}>
            <Alert onClose={(e) => setOpenErrorSnackBar(false)} severity="error">
              {error}
            </Alert>
          </Snackbar>
          <Snackbar open={showWarningAlert} autoHideDuration={5000}>
            <Alert onClose={(e) => setShowWarningAlert(false)} severity="warning">
              {warning}
            </Alert>
          </Snackbar>
        </div>
      </div>
      <div className="right">
        {props.setShowProfile ? (
          <span
            className="link"
            onClick={(e) => {
              props.setDisplayMessage(false);
              props.setShowProfile(true);
            }}
          >
            Go back to coach profile
          </span>
        ) : null}
        <div className="bookBox">
          <h4>Book Now</h4>
          <div className="left-align">
            ${sessionRateWithAdditionalAthletes}/Session
            <br />
            <br />
            <Input
              type="text"
              value={numSessions}
              onChange={(e) => setNumSessions(e.target.value)}
            />
            <span>Sessions</span>
            {props.coach.additionalAthletesRate ? (
              <Box mt={1}>
                <Input
                  type="text"
                  value={numAthletes}
                  onChange={(e) => {
                    if (e.target.value > 100) {
                      return;
                    }

                    setNumAthletes(e.target.value);
                  }}
                />
                <span>Athletes { }</span>
                <Tooltip
                  title={`$${props.coach.additionalAthletesRate}/additional athlete`}
                >
                  <Info
                    className="toolTipIcon"
                    style={{ height: 12, width: 12, color: "#999" }}
                  />
                </Tooltip>
              </Box>
            ) : undefined}
          </div>
          <div className="total">
            <div>Total</div>
            <div>${sessionRateWithAdditionalAthletes * numSessions}</div>
          </div>
          {user != null ? (
            <div>
              <Button className="buttonPrimary" onClick={book}>
                Continue
              </Button>
              <div className="notCharged">You will not be charged yet.</div>
            </div>
          ) : (
            <div>
              <Button className="buttonPrimary" onClick={login}>
                Log In to Book
              </Button>
              <span className="link text-small signup-link" onClick={signup}>
                Create an Account (Free)
              </span>
            </div>
          )}
        </div>
        Need help finding the right coach?
        <br />
        <br />
        Get in Touch:{" "}
        <a className="link" href="mailto:community@athletesuntapped.com">
          community@athletesuntapped.com
        </a>
      </div>
      <LoginSignupDialog
        show={showModal}
        close={() => setShowModal(false)}
        showSignup={showSignup}
        setShowSignup={setShowSignup}
        returnAfterLogin={true}
        sendMessageSignup={true}
      />
    </div>
  );
};
export default AthleteMessageCoach;